<template>
  <div class="EquipmentOperaStatusView">
    <div :class="{ BackgroundBox: expansion, BackgroundBox2: !expansion }">
      <div class="gaugeoutfit2">
        <span class="xian"></span>
        <span class="title">{{groupTitle}}</span>
      </div>
      <el-row :gutter="18" class="grid-content-main">
        <div class="Equipment_search">
        <div class="monitor_r">
            <div class="StateStyle">
              <span class="round" style="background-color: #0bc1ff"></span>
              <span class="title">设备总数：</span>
              <span class="num">{{ EquipmentAmount.AllAmount }}</span>
            </div>
            <div class="StateStyle">
              <span class="round" style="background-color: #00d392"></span>
              <span class="title">运行设备：</span>
              <span class="num">{{ EquipmentAmount.RunningAmount }}</span>
            </div>
            <div class="StateStyle">
              <span class="round" style="background-color: #fe730e"></span>
              <span class="title">待机设备：</span>
              <span class="num">{{ EquipmentAmount.StandByAmount }}</span>
            </div>
            <!-- <div class="StateStyle">
              <span class="round" style="background-color: #d10132"></span>
              <span class="title">报警设备：</span>
              <span class="num">{{ EquipmentAmount.AlarmAmount }}</span>
            </div> -->
            <div class="StateStyle">
              <span class="round" style="background-color: #cccccc"></span>
              <span class="title">停机设备：</span>
              <span class="num">{{ EquipmentAmount.ShutDownAmount }}</span>
            </div>
            <div class="StateStyle">
              <span class="round" style="background-color: #ebc826"></span>
              <span class="title">故障设备：</span>
              <span class="num">{{ EquipmentAmount.BadAmount }}</span>
            </div>
          </div>
        </div>
        <div class="Equipment_list" v-loading="loading"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(1,34,48, 0)">
          <div class="Equipment_list_item" v-for="(item,index) in StatusList" :key="index">
            <div class="Equipment_list_title">{{item.LocationName}}</div>
            <div class="Equipment_list_box">
              <div class="Equipment_item" v-for="(ite,ind) in item.EquipmentInfo" :key="ind" @click="handleGo(item,ite)">
                <div v-if="ite.AlarmState == 1" class="Equipment_icon_bj Equip_item_icon">报警</div>
                <!-- -1 停机 0 待机 3 运行 5 报警 -->
                <div v-if="ite.EquipmentState == 3" class="Equipment_item_yx Equip_item_img"></div>
                <div v-else-if="ite.EquipmentState == '-1'" class="Equipment_item_tj Equip_item_img"></div>
                <div v-else-if="ite.EquipmentState == 0" class="Equipment_item_dj Equip_item_img"></div>
                <div class="Equipment_item_title">
                  {{ite.EquipmentName}}
                </div>
              </div>
            </div>
          </div>
          <span v-show="errorMsg" class="icon">
            <img :src="error" class="icon-img">
            <div class="icon-text">系统错误</div>
          </span>
          <span v-show="emptyMsg" class="icon">
            <img :src="empty" class="icon-img">
            <div class="icon-text">暂无数据</div>
          </span>
          <span v-show="noAuthMsg" class="icon">
            <div class="icon-text">暂无权限</div>
          </span>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { GetEquipmentByStates } from "@/api/EquipmentOperationMonitoring";
import empty from '@/assets/icons/empty02.png'
import error from '@/assets/icons/error.png'
export default {
  components:{

  },
  data() {
    return {
      EquipmentAmount:{},
      StatusAmount: {
        "LocationId": 0,
        "AllAmount": 4,
        "RunningAmount": 0,
        "StandByAmount": 1,
        "AlarmAmount": 0,
        "BadAmount": 0,
        "ShutDownAmount": 3
      },
      "StatusList": [
        // {
        //   "LocationId": 6,
        //   "LocationName": "1#厂房",
        //   "EquipmentInfo": []
        // }
      ],
      groupTitle: this.$route.query.title,
      stateView: this.$route.query.stateView,
      empty:empty,
		  error:error,
      loading:false,
      emptyMsg:false,
      errorMsg:false,
      noAuthMsg:false,
    }
  },
  computed: {
    ...mapGetters(["expansion"]),
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.setExpansion(true);
    });
    this.handleEquipmentByStates()
  },
  methods: {
    ...mapMutations(["setExpansion"]),
    handleEquipmentByStates(){
      this.emptyMsg = false;
      this.errorMsg = false;
      this.noAuthMsg = false;
      this.loading = true;
      this.StatusList = [];
      let params = {}
      if (this.stateView) {
        params = {
          AgencyId:sessionStorage.getItem('agencyId'),
          EquipmentState: this.stateView
        }
      }else{
        params = {
          AgencyId:sessionStorage.getItem('agencyId'),
        }
      }
      GetEquipmentByStates(params).then(res => {
          let obj = res.Content;
          if(res.Code==-1){
            this.StatusList = [];
            this.errorMsg = true;
            this.loading = false;
            this.$notify.info({
              title: '消息',
              message: '获取信息失败'
            });
            return;
          }
          this.loading = false;
          this.EquipmentAmount = obj.amount;
          this.StatusList = obj.data;
      }).catch(err => {
        this.StatusList = [];
        if(err.status == 403){//没有权限
          this.noAuthMsg = true;
        }else{
          this.errorMsg = true;
        }
        this.loading = false;
      })
    },
    handleGo(item,ite){
      this.$router.push({path:"/StatusDetails", 
        query:{
          title:'设备运行状态监测',
          activeId:2,//StatusDetails公用 区分tab active状态
          locationId: item.locationId,//厂房
          EquipmentId: ite.EquipmentId,//设备Id
        }
		  });
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/mixin.scss';
.BackgroundBox {
  position: relative;
  top: 0;
  left: 0px;
  width: 1640px;
  height: 760px;
  z-index: 1;
}
.BackgroundBox2 {
  position: relative;
  top: 0;
  left: 0px;
  width: 1820px;
  height: 760px;
  z-index: 1;
}
.StateStyle{
  display: inline-block;
  width: 135px;
  height: 100%;
}
.StateStyle .round{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 10px;
}
.StateStyle .title{
  @include font_color("font2_color_white");
  font-size: 16px;
}
.StateStyle .num{
  @include font_color("font2_color_white");
  font-size: 16px;
}
.Equipment_item:hover .Equipment_item_title{
  background-image: url('../../../static/images/title_hov.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.Equipment_icon_yx{
  background-image: url('../../../static/images/run_icon.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.Equipment_icon_dj{
  background-image: url('../../../static/images/Standby_icon.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.Equipment_icon_tj{
  background-image: url('../../../static/images/fault_icon.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.Equipment_icon_gz{
  background-image: url('../../../static/images/halt_icon.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.Equipment_icon_bj{
  background-image: url('../../../static/images/police_icon.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}


.Equipment_item_yx{
  background-image: url('../../../static/images/equip_yx.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.Equipment_item_dj{
  background-image: url('../../../static/images/equip_dj.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.Equipment_item_tj{
  background-image: url('../../../static/images/equip_tj.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.Equipment_item_gz{
  background-image: url('../../../static/images/equip_gz.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
  .Equipment_search{
    display: flex;
    align-items: center;
    height: 80px;
    line-height: 80px;
  }
  .grid-content-main{
    height: calc(100% - 38px);
    padding: 0 20px;
    // @include background_color("background_color_white");
    // background: url('../../../static/main/EquipmentMonitoringBg.png');
    background: url('../../../static/images/bck_bord_big.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .Equipment_list{
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill,392px);
    grid-gap: 10px 10px;
    height: calc(100% - 81px);
    overflow-y: auto;
  }
  .Equipment_list_item{
    width: 352px;
    height: 275px;
    padding: 20px 20px 15px;
    background: url('../../../static/images/status_item.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .Equipment_list_title{
    height: 32px;
    line-height: 32px;
    background: url('../../../static/images/status_title.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-left: 20px;
  }
  .Equipment_list_box{
    height: calc(100% - 72px);
    overflow-y: auto;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill,135px);
    grid-gap: 20px;
    padding: 20px;
  }
  .Equipment_item{
    width: 135px;
    height: 135px;
    background-image: url('../../../static/images/equipView_nor.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    &:hover{
      background-image: url('../../../static/images/equipView_hov.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .Equipment_item_img{
    background-image: url('../../../static/images/title_nor.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &:hover{
      background-image: url('../../../static/images/title_hov.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .Equip_item_icon{
    width: 40px;
	  height: 25px;
	  line-height: 25px;
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 14px;
  }
  .Equip_item_img{
    width: 40px;
    height: 40px;
  }
  .Equipment_item_title{
    background-image: url('../../../static/images/title_nor.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 95px;
    height: 25px;
    line-height: 25px;
    margin-top: 20px;
  }
}
@media only screen and (min-aspect-ratio: 1920/1000 ) {
  .Equipment_search{
    display: flex;
    align-items: center;
    height: 8vh;
    line-height: 8vh;
  }
  .grid-content-main{
    height: calc(100% - 3.8vh);
    padding: 0 20px;
    // @include background_color("background_color_white");
    // background: url('../../../static/main/EquipmentMonitoringBg.png');
    background: url('../../../static/images/bck_bord_big.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .Equipment_list{
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill,392px);
    grid-gap: 10px 10px;
    height: calc(100% - 8.1vh);
    overflow-y: auto;
  }
  .Equipment_list_item{
    width: 352px;
    height: 27.5vh;
    padding: 20px 20px 15px;
    background: url('../../../static/images/status_item.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .Equipment_list_title{
    height: 3.2vh;
    line-height: 3.2vh;
    background: url('../../../static/images/status_title.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-left: 20px;
  }
  .Equipment_list_box{
    height: calc(100% - 7.2vh);
    overflow-y: auto;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill,135px);
    grid-gap: 20px;
    padding: 20px;
  }
  .Equipment_item{
    width: 135px;
    height: 13.5vh;
    background-image: url('../../../static/images/equipView_nor.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    &:hover{
      background-image: url('../../../static/images/equipView_hov.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .Equipment_item_img{
    background-image: url('../../../static/images/title_nor.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &:hover{
      background-image: url('../../../static/images/title_hov.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .Equip_item_icon{
    width: 40px;
	  height: 2.5vh;
	  line-height: 2.5vh;
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 14px;
  }
  .Equip_item_img{
    width: 40px;
    height: 4vh;
  }
  .Equipment_item_title{
    background-image: url('../../../static/images/title_nor.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 95px;
    height: 2.5vh;
    line-height: 2.5vh;
    margin-top: 20px;
  }
}
</style>